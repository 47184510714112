$thick-border: 1px solid #000;

$default-fonts: Arial, Arimo, "Liberation Sans", sans-serif;

$primary-background-color: #183444;

$link-color: #183444;
$link-color-hover: #324E5E;

$button-blue-color: #183444;
$button-blue-color-hover: #324E5E;

body {
    font-family: $default-fonts;
}

.ui.inverted.menu {
    background: $primary-background-color;
}

.ui.vertical.inverted.menu .menu .item, .ui.vertical.inverted.menu .menu .item a:not(.ui) {
    color: rgba(255, 255, 255, .65);
}

a, .ui.breadcrumb a {
    color: $link-color;

    &:hover {
        color: $link-color-hover;
    }
}

.ui.blue.button, .ui.blue.buttons .button,
.ui.primary.button, .ui.primary.buttons .button{
    background-color: $button-blue-color;

    &:hover {
        background-color: $button-blue-color-hover;
    }
}

.ui.teal.label, .ui.teal.labels .label {
    background-color: $button-blue-color !important;
    border-color: $button-blue-color !important;
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: $button-blue-color !important;
}

.jexcel_corner {
    z-index: 2;
}

.jexcel_contextmenu {
    display: none!important;
}

#schedule table {
    text-align: center;
    display: table;
    white-space: pre-wrap;
    border-bottom: 1px solid #ddd;

    .days {
        min-width: 32px;
    }

    .weekend {
        background-color: #fffda4;
    }

    .holiday {
        background-color: #ffa4a4;
    }

    tr {
        display: table-row;
    }

    th {
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        font-size: 11px;
    }

    tbody {
        tr:nth-child(3n-2) td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                border-bottom: $thick-border;
            }

            &:nth-child(5) {
                border-right: $thick-border;
            }
        }

        tr:nth-child(3n) td {
            border-bottom: $thick-border;
        }

        tr:last-child {
            td.readonly {
                border-bottom: 0;
            }
        }

        tr:first-child td {
            border-top: $thick-border;
        }
    }

    .border-thick.right {
        border-right: $thick-border;
    }

    .border-none.right {
        border-right: 0 !important;
    }

    .jexcel_label {
        border-left: none;
    }

    th.rotate {
        position: relative;
        padding: 10px;
        min-width: 30px;
        vertical-align: bottom;

        & > div {
            transform-origin: 0 50%;
            transform: rotate(-90deg);
            display: block;
            position: absolute;
            bottom: -4px;
            left: 50%;
            text-align: left;
            width: 255px;
            line-height: 12px;
        }

        &.sm-1 > div {
            width: 237px;
        }

        &.sm-2 > div {
            width: 217px;
        }
    }
}

.pull-right {
    float: right;
}

.ui.dropdown .menu>.message {
    max-width: 300px;
    min-width: 220px;
    white-space: normal;
}

.ui.dropdown .menu>.message {
    max-width: 300px;
    min-width: 220px;
    white-space: normal;
}

#notification-menu {
    border: 0;

    .message .wrapper {
        color: #000;

        .title {
            font-size: 18px;
        }
    }
}

.pull-right {
    float: right;
}

.clear-wrapper{
    position: relative;
    display: block;
    width: 100%;
}

.clear-wrapper .datepicker-clear {
    display: block;
    top: calc(50% - 6px);
    right: 10px;
    position: absolute;
    margin: 0;
    cursor: pointer;
    line-height: 1;
}

.ui.input input {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: $default-fonts;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.button {
    font-family: $default-fonts;
}

.select2-container .select2-selection--single {
    height: 38px;
    width: 100%;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: 0 0 0 0 transparent inset;
    padding: .5em;
    color: rgba(0,0,0,.87);
    -webkit-transition: color .1s ease,border-color .1s ease;
    transition: color .1s ease,border-color .1s ease;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 23px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
}

.select2-dropdown {
    border: 1px solid rgba(34,36,38,.15);
}

.select2-container {
    width: 100% !important;
}

.ui.form .fields.flex {

    &.field {
        .field:first-child {
            padding-left: 0;
        }

        .field:last-child {
            padding-right: 0;
        }
    }

    &.align-end {
        align-items: flex-end;
    }

    &.grow-first > .field:first-child {
        flex-grow: 1;
    }
}

.ui.buttons form {
    &:first-child > .button {
        border-left: none;
        margin-left: 0;
        border-top-left-radius: .28571429rem;
        border-bottom-left-radius: .28571429rem;
    }

    &:last-child > .button {
        border-right: none;
        margin-right: 0;
        border-top-right-radius: .28571429rem;
        border-bottom-right-radius: .28571429rem;
    }
}

.ui.strip {
    display: flex;

    &.space-between {
        justify-content: space-between;
    }

    .item {
        padding-left: 8px;
        padding-right: 8px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        label {
            font-weight: 700;
        }
    }
}

.ui[class*="left-right labeled"].icon.button {

    padding-left: 4.07142857em!important;
    padding-right: 4.07142857em!important;

    &>.icon {

        &.left {
            left: 0;
            right: auto;
            border-radius: 0;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        &.right {

        }
    }
}
.small {
    font-size: 12px;

    .ui.button, .ui.buttons .button, .ui.buttons .or {
        font-size: 12px;
    }
}

.text-small {
    font-size: 12px;
}

.ui.form {

    .questionnaire-content {

        .flex-fields .field {
            width: 100%;
            display: flex;

            > label {
                font-weight: 500;
                flex: 0 0 35%;
                align-self: center;
                padding-right: 8px;
            }

            input {
                flex: 0 0 65%;
            }
        }
    }
}

.flex-list {

    [data-form-collection="item"] {
        display: flex;
        align-items: flex-end;

        .flex-item {
            display: flex;
            align-items: flex-end;

            margin-bottom: 1em;

            label {
                word-break: break-word;
                padding: 0 4px;
            }

            .field {
                margin: 0 6px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 12px;
                }
            }
        }

        &:not(:first-child) {
            .flex-item label {
                visibility: hidden;
                height: 0;
            }
        }
    }
}

.ui.menu.fixed {
    #sidebar-toggle {
        color: #fff;
        padding: .7em 1.4em;

        & > .icon {
            font-size: 22px;
        }

        & > .text {
            padding-left: 8px;
            font-weight: 700;
        }
    }

    #sidebar + & {
        #sidebar-toggle > .icon.close {
            display: none;
        }
    }

    #sidebar.visible + & {
        #sidebar-toggle > .icon {
            &.close {
                display: initial;
            }

            &.sidebar {
                display: none;
            }
        }
    }
}

.ui.visible.left.sidebar ~ .fixed.menu, .ui.visible.left.sidebar ~ .pusher {
    @media (max-width: 767px) {
        padding-right: 0;
    }
}

.ui.vertical.menu .menu .item {
    @media (max-width: 767px) {
        font-size: 0.9em;

        padding: 0.8em 1.33333333em;
    }
}
