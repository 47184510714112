/**
 * (c) 2013 Jexcel Plugin | Bossanova UI
 * http://www.github.com/paulhodel/jexcel
 *
 * @author: Paul Hodel <paul.hodel@gmail.com>
 * @description: Web spreadsheet
 */
 
.jexcel
{
    border-collapse:separate;
    table-layout:fixed;
    white-space: nowrap;
    empty-cells:show;
    border-top:1px solid transparent;
    border-left:1px solid #ccc;
    border-right:1px solid transparent;
    border-bottom:1px solid transparent;
    background-clip:padding-box;
    background-color:#fff;
}
@media all and (-ms-high-contrast:none)
{
    .jexcel
    {
        width:0px;
    }
}
@media screen\9
{
    .jexcel
    {
        width:0px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .jexcel
    {
        width:0px;
    }
}
.jexcel > thead > tr, .jexcel > tbody > tr
{
    display:flex;
}
.jexcel > thead > tr > td 
{
    border-left:1px solid transparent;
    border-right:1px solid #ccc;
    border-top:1px solid #ccc;
    border-bottom:1px solid transparent;
    background-color:#f3f3f3;
    padding:4px;
    cursor:pointer;
    overflow:hidden;
    box-sizing: border-box;
}
.jexcel > thead > tr > td.selected
{
    background-color:#dcdcdc;
}

.jexcel > tbody
{
    padding-right:3px;
    padding-bottom:1px;
}
.jexcel > tbody > tr > td.edition
{
    padding:0px;
    padding-left:4px;
}
.jexcel > tbody > tr > td:first-child
{
    background-color:#f3f3f3;
    width:30px;
    text-align:center;
}
.jexcel > tbody > tr > td
{
    border-left:1px solid transparent;
    border-right:1px solid #ccc;
    border-top:1px solid transparent;
    border-bottom:1px solid #ccc;
    padding:4px;
    overflow:hidden;
    white-space: nowrap;
    box-sizing: border-box;
}
.jexcel > tbody > tr:first-child > td
{
    border-top:1px solid #ccc;
}
.jexcel > tbody > tr > td > img
{
    display: block;
}
.jexcel > tbody > tr > td.readonly
{
    color:rgba(0,0,0,0.3)
}
.jexcel > tbody > tr.selected > td:first-child
{
    background-color:#dcdcdc;
}
.jexcel .highlight
{
    background-color:rgba(0,0,0,0.05);
}
.jexcel .highlight-top
{
    border-top:1px solid #000 !important;
}
.jexcel .highlight-left
{
    border-left:1px solid #000;
}
.jexcel .highlight-right
{
    border-right:1px solid #000;
    box-shadow: 1px 0px #ccc;
    -webkit-box-shadow: 1px 0px #ccc;
    -moz-box-shadow: 1px 0px #ccc;
}
.jexcel .highlight-bottom
{
    border-bottom:1px solid #000;
    box-shadow: 0px 1px #ccc;
    -webkit-box-shadow: 0px 1px #ccc;
    -moz-box-shadow: 0px 1px #ccc;
}
.jexcel .highlight-bottom.highlight-right
{
    border-bottom:1px solid #000;
    box-shadow: 1px 1px #ccc;
    -webkit-box-shadow: 1px 1px #ccc;
    -moz-box-shadow: 1px 1px #ccc;
}
.jexcel .selection
{
    background-color:rgba(0,0,0,0.05);
}
.jexcel .selection-left
{
    border-left:1px dotted #000;
}
.jexcel .selection-right
{
    border-right:1px dotted #000;
}
.jexcel .selection-top
{
    border-top:1px dotted #000;
}
.jexcel .selection-bottom
{
    border-bottom:1px dotted #000;
}
.jexcel_corner
{
    position:absolute;
    background-color: rgb(0, 0, 0);
    height: 5px;
    width: 5px;
    border: 2px solid rgb(255, 255, 255);
    top:-200px;
    left:-200px;
    cursor:crosshair;
    box-sizing: initial;
    z-index:1;
}
.jexcel .editor
{
    outline:0px solid transparent;
    overflow:hidden;
    white-space: nowrap;
    text-align:left;
    padding:0px;
}

.jexcel > tbody > tr > td > select, .jexcel > tbody > tr > td > input, .jexcel > tbody > tr > td > textarea
{
    border:0px;
    border-radius:0px;
    outline:0px;
    width:100%;
    margin:0px;
    padding:0px;
    background-color:transparent;
}

.jexcel > tbody > tr > td > textarea
{
    resize: none;
}

.jexcel > tbody > tr > td > input[type=checkbox]
{
    width:12px;
    margin-top:2px;
}

.jexcel > tbody > tr > td > select
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 40%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
}

.jexcel, .jexcel td, .jexcel_corner {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.jexcel_textarea
{
    position:absolute;
    top:-999px;
    left:-999px;
    width:1px;
    height:1px;
}

.jexcel .results
{
    position:absolute;
    min-height:200px;
    max-height:300px;
    width:220px;
    background-color:#fff;
    overflow-y:scroll;
    z-index:99;
    text-align:left;
    border:1px solid #ccc;
    margin-top:8px;
    margin-left:-5px;
}
.jexcel .results li
{
    list-style:none;
    padding:6px;
    cursor:pointer;
}
.jexcel .results li:hover, .jexcel .results li.selected
{
    background-color:#1e90ff;
    color:#ffffff;
}
.jexcel .dragline
{
    position:absolute;
}
.jexcel .dragline div
{
    position:relative;
    top:-6px;
    height:5px;
    width:22px;
}
.jexcel .dragline div:hover
{
    cursor:move;
}

.jexcel .onDrag
{
    background-color:rgba(0,0,0,0.6);
}

.jexcel .error
{
    border:1px solid red;
}

.jexcel .arrow-up
{
    width: 0; 
    height:0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #444;
    position:absolute;
    margin-left:4px;
    margin-top:8px;
}

.jexcel .arrow-down
{
    width: 0; 
    height:0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #444;
    position:absolute;
    margin-left:4px;
    margin-top:8px;
}

.jexcel .resizing
{
    border-right-style:dotted !important;
    border-right-color:black !important;
}

.jexcel_contextmenu
{
    display:none;
    position:absolute;
    z-index:100;
    background: #fff;
    color: #555;
    font-family: sans-serif;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    -webkit-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    -moz-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    padding: 0px;
    border: 1px solid #C6C6C6;
    padding-top:5px;
}

.jexcel_contextmenu a
{
    display: block;
    color: #555;
    text-decoration: none;
    padding: 6px 8px 6px 30px;
    width: 250px;
    position: relative;
    cursor: default;
}

.jexcel_contextmenu a span
{
    color: #a1a192;
    float: right;
    margin-right:10px;
}

.jexcel_contextmenu a:hover
{
    background: #ebebeb;
}

.jexcel_contextmenu hr
{
    border: 1px solid #e9e9e9;
    border-bottom: 0;
}

.jexcel .jexcel_arrow
{
    float:right;
    position:relative;
    top:8px;
    left:-13px;
}

.jexcel #jexcel_arrow
{
    width: 0; 
    height:0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #bbb;
    position:absolute;
    cursor:pointer;
}


.jexcel > tbody::-webkit-scrollbar
{
    margin-left:6px;
    width:6px;
}
.jexcel > tbody::-webkit-scrollbar-track
{
    background: #f3f3f3;
}
.jexcel > tbody::-webkit-scrollbar-thumb
{
    background: #888;
}
.jexcel > tbody::-webkit-scrollbar-thumb:hover
{
    background: #555; 
}
